import React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as Styled from './newsCardStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import moment from 'moment';

const NewsCard = ({ sectionData, onMouseClick }) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let dateObj = null;
  return (
    <Styled.NewsContainer>
      {sectionData?.calenderEvent?.map(
        (item, key) => (
          (dateObj = new Date(item?.date)),
          (item.date.replace('-', '/'),
          (
            <Styled.InnerContainer key={key}>
              <Row className="g-0">
                <Col className="p-0">
                  <Styled.NewsListLeftSection>
                    <Styled.DateCalender>
                      <Styled.DateCalenderMonth>
                        {
                          monthNames[
                            moment(item?.date, 'MM-DD-YYYY').get('month')
                          ]
                        }
                      </Styled.DateCalenderMonth>
                      <Styled.DateCalenderDay>
                        {(moment(item?.date, 'MM-DD-YYYY').get('date') < 10
                          ? '0'
                          : '') + moment(item?.date, 'MM-DD-YYYY').get('date')}
                      </Styled.DateCalenderDay>
                    </Styled.DateCalender>
                  </Styled.NewsListLeftSection>

                  <Styled.NewsListRightSection>
                    <Styled.NewsTitleContainer>
                      <Styled.NewsTitle
                        onClick={() =>
                          onMouseClick(item, sectionData.multimedia)
                        }
                      >
                        {item?.textWithPath?.text}
                      </Styled.NewsTitle>
                    </Styled.NewsTitleContainer>
                    <Styled.NewsDescription>
                      {item && (
                        <>
                          {documentToReactComponents(
                            JSON.parse(item?.text?.raw)
                          )}
                        </>
                      )}
                    </Styled.NewsDescription>
                  </Styled.NewsListRightSection>
                </Col>
              </Row>
            </Styled.InnerContainer>
          ))
        )
      )}
    </Styled.NewsContainer>
  );
};

export default NewsCard;
