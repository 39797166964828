import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const NewsContainer = styled(Container)`
  padding: 50px 50px 15px 50px !important;
  border: 2px solid ${AppColors.gray};
  max-width: 100% !important;
  margin-top: -4px;
  border-top: 4px solid #d9d9d9;
  @media (max-width: 767px) {
    margin-top: 0;
    padding: 30px !important;
    border-top: none;
    border-bottom: none;
  }
`;

export const InnerContainer = styled.div`
  padding-top: 20px;
  margin: 0 20px 40px 20px;
  border-bottom: none;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: ${AppColors.Black};
  border-bottom: 1px solid ${AppColors.backgroundBlack15};
  :last-child {
    border-bottom: none;
  }
  @media (max-width: 991px) {
    padding-top: 0;
    margin: 0 0 40px 0;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 23px;
  }
`;

export const NewsListLeftSection = styled.div`
  width: 13%;
  float: left;
  @media (max-width: 1799px) {
    width: 15%;
  }
  @media (max-width: 1199px) {
    width: 20%;
  }
  @media (max-width: 991px) {
    width: 100%;
    float: none;
  }
`;

export const DateCalender = styled.div`
  width: 125px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
  @media (max-width: 1399px) {
    width: 110px;
  }
  @media (max-width: 991px) {
    margin-bottom: 35px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const DateCalenderMonth = styled.div`
  padding: 8px 0;
  font-size: 21px;
  line-height: 21px;
  font-family: interstate, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  color: ${AppColors.White};
  background: ${AppColors.LightGreen};
  @media (max-width: 767px) {
    padding: 6px 0 4px 0;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const DateCalenderDay = styled.div`
  padding: 15px 0;
  font-size: 60px;
  line-height: 60px;
  font-family: interstate, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-transform: uppercase;
  text-align: center;
  color: ${AppColors.LightGreen};
  background: ${AppColors.White};
  @media (max-width: 767px) {
    padding: 10px 0;
    font-size: 50px;
    line-height: 50px;
  }
`;

export const NewsListRightSection = styled.div`
  width: 87%;
  float: left;
  @media (max-width: 1799px) {
    width: 85%;
  }
  @media (max-width: 1199px) {
    width: 80%;
  }
  @media (max-width: 991px) {
    width: 100%;
    float: none;
  }
`;

export const NewsTitleContainer = styled.a`
  text-decoration: none;
  color: ${AppColors.LightGreen};
`;

export const NewsTitle = styled.h3`
  position: relative;
  margin-bottom: 25px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  color: ${AppColors.LightGreen};
  cursor: pointer;
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 20px;
    line-height: 22px;
  }
`;

export const NewsDescription = styled.div`
  margin-bottom: 50px;
  text-align: left !important;
  padding: 0;
`;
