import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../../styles/colors';
import Arrow_Green from '../../../images/icon-arrow-green-right.png';
import IconPDF from '../../../images/icon-pdf.png';

export const ScrollSpan = styled.span``;

export const NewsDetailsContainer = styled(Container)`
  background: ${AppColors.WhiteF2};
  margin-top: -1px;
  max-width: 100% !important;
  position: relative;
  ${ScrollSpan} {
    position: absolute;
    top: -50px;
  }
`;

export const NewsDetailsSubContainer = styled.div`
  width: 1700px;
  margin: 0 auto;
  padding: 70px 0 50px 0;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: ${AppColors.Black};
  @media (max-width: 1799px) {
    width: 1300px;
  }
  @media (max-width: 1399px) {
    width: 1200px;
  }
  @media (max-width: 1199px) {
    width: 940px;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 50px 30px;
    font-size: 15px;
    line-height: 23px;
  }
`;

export const NewsDetailsContents = styled.div`
  text-align: center; ;
`;

export const NewsDetailsContentsA = styled.a`
  word-wrap: break-word;
`;

export const NewsDetailsHeading = styled.h2`
  text-align: left !important;
  position: relative;
  margin: 0 0 40px 0;
  padding: 0 0 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 25px;
    line-height: 27px;
  }
  color: ${AppColors.LightGreen};
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    transition: all 0.2s ease-out;
    background: ${AppColors.backgroundBlack1};
  }
`;

export const NewsDetailsP = styled.p`
  text-align: left !important;
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
`;

export const NewsLinkPdf = styled(NewsDetailsContentsA)`
  display: inline-block;
  position: relative;
  padding: 20px 45px 0 90px;
  margin: 0 0 40px 0;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  text-decoration: none;
  color: ${AppColors.LightGreen};
  :hover {
    text-decoration: none;
    color: ${AppColors.LightBlue};
    ::before {
      left: 10px;
    }
  }
  ::before {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 82px;
    content: '';
    background: url(${IconPDF}) no-repeat center center;
    background-size: 64px auto;
    transition: all 0.25s ease-out;
  }
`;

export const ButtonDiv = styled.div`
  cursor: pointer;
  text-align: left;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const ListContainer = styled.ul`
  margin: 0 !important;
  padding: 0 !important;
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 10px 0 10px 30px;
  list-style: none;
  background: url(${Arrow_Green}) no-repeat 0 19px;
  background-size: 10px auto;
  ${NewsDetailsP} {
    margin: 0 !important;
  }
`;

export const HorizontalBreak = styled.hr`
  margin: 0;
  clear: none;
  opacity: 1;
`;

export const LinkStyle = styled.a`
  color: ${AppColors.Green};
  text-decoration: none;
`;

export const Header3 = styled.h3`
  position: relative;
  margin: 0 0 40px 0;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  line-height: 40px;
  text-align: left;
  color: ${AppColors.Blue};
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 20px;
    line-height: 22px;
  }
`;
