import React from 'react';
import AppButton from '../../../components/appButton/appButton';
import * as Styled from './newsDetailsStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const NewsDetails = ({ onButtonClick, sectionData, buttonData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.NewsDetailsP>{children}</Styled.NewsDetailsP>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.NewsDetailsHeading>{children}</Styled.NewsDetailsHeading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.Header3>{children}</Styled.Header3>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.ListContainer>{children}</Styled.ListContainer>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node.data.target.sys.id);

        if (asset) {
          return (
            <Styled.NewsLinkPdf href={asset?.node?.file?.url} target={'_blank'}>
              {children}
            </Styled.NewsLinkPdf>
          );
        }
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + node?.data?.uri
             )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const optionsButtonStyle = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.ButtonDiv onClick={onButtonClick}>
          <AppButton buttonText={children}></AppButton>
        </Styled.ButtonDiv>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  React.useEffect(async () => {
    const waitedID = await new Promise((resolve) => {
            document?.getElementById('details') && resolve(true);
          });
    if (waitedID) {
      document?.getElementById('details')?.scrollIntoView(true);
    }
  }, []);

  return (
    <Styled.NewsDetailsContainer className="g-0">
      <Styled.ScrollSpan id="details"></Styled.ScrollSpan>
      <Styled.NewsDetailsSubContainer>
        <Styled.NewsDetailsContents>
          {sectionData && (
            <>
              {documentToReactComponents(
                JSON.parse(sectionData?.details?.raw),
                optionsMainStyle
              )}
            </>
          )}

          {buttonData && (
            <>
              {documentToReactComponents(
                JSON.parse(buttonData[0]?.buttons?.raw),
                optionsButtonStyle
              )}
            </>
          )}
        </Styled.NewsDetailsContents>
      </Styled.NewsDetailsSubContainer>
    </Styled.NewsDetailsContainer>
  );
};

export default NewsDetails;
