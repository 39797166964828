import * as React from 'react';
import { Tab } from 'react-bootstrap';
import * as Styled from './communityNewsStyle';
import NewsCard from '../../../pageSections/news/newsCard/newsCard';
import NewsDetails from '../../../pageSections/news/newsDetails/newsDetails';

const CommunityNews = ({ sectionData }) => {
  const [showNewsDetails, setShowNewsDetails] = React.useState(false);
  const [newsDetailsData, setNewsDetailsData] = React.useState(null);
  const [buttonData, setButtonData] = React.useState(null);
  const onMouseClick = (value, buttonData) => {
    setNewsDetailsData(value);
    setShowNewsDetails(!showNewsDetails);
    setButtonData(buttonData);
  };
  const defaultYear = "2024";
  return (
    <>
      {!showNewsDetails ? (
        <Styled.CommunityNewsContainer className="px-0">
          <Styled.InnerContainer>
            <Styled.MainRow className="g-0">
              <>
                <Styled.Header>
                  <span id="maintenance"></span>
                  {sectionData?.title}
                </Styled.Header>
                <Styled.TabMainDiv className="d-none d-md-block">
                  <Styled.TabsStyle
                    defaultActiveKey={defaultYear}
                    id="uncontrolled-tab-example"
                  >
                    {sectionData?.calenderEventsList?.map((data, key) => (
                      <Tab eventKey={data?.title} title={data?.title} key={key}>
                        <NewsCard
                          sectionData={data}
                          onMouseClick={onMouseClick}
                        />
                      </Tab>
                    ))}
                  </Styled.TabsStyle>
                </Styled.TabMainDiv>

                <Styled.AccordionMainDiv className="d-md-none">
                  <Styled.AccordionContainer defaultActiveKey={[defaultYear]} flush>
                    {sectionData?.calenderEventsList?.map((data, index) => (
                      <React.Fragment key={index}>
                        <Styled.AccordionItem
                          eventKey={data?.title}
                          Key={data?.type}
                        >
                          <Styled.AccordionHeader>
                            {data?.title}
                          </Styled.AccordionHeader>
                          <Styled.AccordionBody>
                            <NewsCard
                              sectionData={data}
                              onMouseClick={onMouseClick}
                            />
                          </Styled.AccordionBody>
                        </Styled.AccordionItem>
                      </React.Fragment>
                    ))}
                  </Styled.AccordionContainer>
                </Styled.AccordionMainDiv>
              </>
            </Styled.MainRow>
          </Styled.InnerContainer>
        </Styled.CommunityNewsContainer>
      ) : (
        <NewsDetails
          sectionData={newsDetailsData}
          buttonData={buttonData}
          onButtonClick={(e) => setShowNewsDetails(!showNewsDetails)}
        />
      )}
    </>
  );
};

export default CommunityNews;
